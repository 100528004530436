import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/shared-ui'
import type { ValidationsProps } from './validation-props'
import { Widget } from '@uploadcare/react-widget'

export interface UploadCareFileProps {
    id: string
    label?: string
    hint?: string
    fromUrl?: boolean
    imagesOnly?: boolean
    multiple?: boolean
    defaultValue?: string[] | string
    placeholder?: string
    disabled?: boolean
    className?: string
    rules?: Omit<ValidationsProps, 'valueAsDate'>
}

interface UploadCareFile {
    uuid: string
    count: number
}

export const UploadCareFile: React.FC<UploadCareFileProps> = ({
    id,
    label,
    hint,
    fromUrl = false,
    imagesOnly = false,
    multiple = false,
    defaultValue,
    placeholder,
    className,
    rules,
    disabled,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const [tempValue, setTempValue] = React.useState(defaultValue)

    const isError = Boolean(errors[id])

    const getValue = (file: UploadCareFile) => {
        if (!multiple) return file.uuid

        const urls: string[] = []
        console.log()
        // UploadCare uploads everything as a group, so we
        // have to extract individual urls of files
        for (let i = 0; i < file.count; i += 1) {
            urls.push(`${file.uuid}/nth/${i}`)
        }

        return urls
    }

    return (
        <Controller
            control={control}
            name={id}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
                <div className={classNames('relative', className)}>
                    <Widget
                        // hack as there is a bug in their typings and doesn't
                        // allow array here, but it actually accepts in the code
                        // @ts-ignore
                        value={tempValue}
                        clearable
                        publicKey="edc33e21ddb7b1a4cd9a"
                        imagesOnly={imagesOnly}
                        multiple={multiple}
                        systemDialog={!multiple}
                        tabs={fromUrl ? 'file url' : 'file'}
                        onChange={(fileInfo) => {
                            setTempValue(fileInfo.uuid as string)
                            // Wrong types
                            // @ts-ignore
                            field.onChange(getValue(fileInfo))
                        }}
                    />

                    {isError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                            />
                        </div>
                    )}
                </div>
            )}
        />
    )
}
