import * as React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/shared-ui'
import { ValidationsProps } from './validation-props'
import { ErrorText } from './error-text'

interface Option {
    id: string | number
    Icon?: React.FC<React.ComponentProps<'svg'>>
    title: string
    description?: string
    value?: string
    discount?: string
    disabled?: boolean
}

export interface RadioCardsProps {
    id: string
    errorText?: string
    options: Option[]
    defaultValue?: string | number
    perRow?: string
    rules?: Omit<ValidationsProps, 'valueAsDate'>
}

export const RadioCards: React.FC<RadioCardsProps> = ({
    id,
    options,
    errorText,
    rules,
    perRow,
    defaultValue,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const {
        field: { onChange, value },
    } = useController({
        name: id,
        control,
        rules,
        defaultValue,
    })

    const isError = React.useMemo(() => Boolean(errors[id]), [errors[id]])

    return (
        <>
            <div className="mt-2 relative">
                <RadioGroup value={value} onChange={onChange}>
                    <div
                        className={classNames('flex flex-row flex-wrap gap-4')}
                    >
                        {options.map((option) => (
                            <RadioGroup.Option
                                key={`${option.id}${option.value}`}
                                value={option.id}
                                disabled={option.disabled}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked
                                            ? 'border-transparent'
                                            : 'border-gray-300',
                                        active
                                            ? 'ring-indigo-500'
                                            : 'ring-transparent',
                                        'ring-2 relative bg-gray-50 border rounded-lg px-3 py-2 flex cursor-pointer focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <div className="flex-1 flex flex-row gap-2 sm:gap-3 pr-1 items-center overflow-hidden">
                                            {option.Icon && (
                                                <div className="flex flex-none">
                                                    <option.Icon className="h-4 w-4 sm:h-5 sm:w-5" />
                                                </div>
                                            )}
                                            <div className="flex flex-col grow sm:basis-full shrink-0">
                                                <RadioGroup.Label
                                                    as="span"
                                                    className="flex text-sm text-slate-800"
                                                >
                                                    {option.title}
                                                </RadioGroup.Label>
                                                {option.description && (
                                                    <RadioGroup.Description
                                                        as="span"
                                                        className="mt-1 flex items-center text-sm text-gray-500"
                                                    >
                                                        {option.description}
                                                    </RadioGroup.Description>
                                                )}
                                                {option.value && (
                                                    <RadioGroup.Description
                                                        as="span"
                                                        className="mt-3 text-sm font-medium text-gray-900"
                                                    >
                                                        {option.value}
                                                        <span className="ml-3 line-through text-yellow-500">
                                                            {option.discount}
                                                        </span>
                                                    </RadioGroup.Description>
                                                )}
                                            </div>
                                        </div>
                                        <CheckCircleIcon
                                            className={classNames(
                                                !checked ? 'hidden' : '',
                                                'h-4 sm:h-4 text-indigo-600 mt-0.5'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <div
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked
                                                    ? 'border-indigo-500'
                                                    : 'border-transparent',
                                                'absolute -inset-px rounded-lg pointer-events-none'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
                {isError && (
                    <div className="absolute inset-y-0 left-0 pr-3 flex items-start pointer-events-none">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </div>
            {errorText && <ErrorText id={id}>{errorText}</ErrorText>}
        </>
    )
}
