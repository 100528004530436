import * as React from 'react'
import { useQuery } from 'react-query'
import api from '../api'
import { Autocomplete } from './autocomplete'

interface Option {
    address: string
    coordinates: { lat: number; lng: number }
}

export interface AddressAutocompleteProps {
    id: string
    defaultValue?: Option | null
}

function formatOptions(options: Option[]) {
    return (options || []).map((i: any) => ({
        id: i.address,
        title: i.address,
        coordinates: i.coordinates,
    }))
}

export const AddressAutocomplete = ({
    id,
    defaultValue,
}: AddressAutocompleteProps) => {
    const [term, setTerm] = React.useState('')

    const { data, isError, isLoading } = useQuery(['address', term], () =>
        api.get(`geo/autocomplete?term=${term}`)
    )

    const options = React.useMemo(() => {
        if (!term && defaultValue) return formatOptions([defaultValue])
        return formatOptions(data?.data)
    }, [data?.data])

    return (
        <div>
            <Autocomplete
                id={id}
                options={options}
                placeholder="Start typing..."
                defaultValue={defaultValue ? defaultValue.address : undefined}
                isLoading={isLoading}
                onQuery={(term) => setTerm(term)}
                displayValue={(item) => item.title}
                rules={{ required: true }}
            />
        </div>
    )
}
