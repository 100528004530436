import * as React from 'react'
import { classNames } from '../shared-ui'
import { twMerge } from 'tailwind-merge'

export interface LabelProps {
    id: string
    hintText?: string | React.ReactNode
    subText?: string | React.ReactNode
    className?: string
}

export const Label: React.FC<LabelProps> = ({
    id,
    hintText,
    subText,
    className,
    children,
}) => {
    return (
        <>
            <div
                className={twMerge(
                    classNames('flex justify-between text-gray-900', className)
                )}
            >
                <label htmlFor={id} className="block text-sm font-medium">
                    {children}
                </label>
                {hintText && (
                    <span className="text-sm text-gray-500" id={`${id}-hint`}>
                        {hintText}
                    </span>
                )}
            </div>
            {subText && (
                <div className="flex justify-between text-slate-500 text-[13px] mb-1">
                    {subText}
                </div>
            )}
        </>
    )
}
