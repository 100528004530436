import * as React from 'react'
import { useFormContext } from 'react-hook-form'

export interface SimpleSelectProps {
    field: string
}

export const WatchField: React.FC<SimpleSelectProps> = ({
    children,
    field,
}) => {
    const { watch } = useFormContext()

    const value = watch(field)

    if (typeof children === 'function') {
        return <>{children(value)}</>
    }

    // Render null or some default component when the value is not yet available.
    return value ? <>{children}</> : null
}
