import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/shared-ui'
import type { ValidationsProps } from './validation-props'
import { ErrorText } from './error-text'

export interface InputProps {
    id: string
    type:
        | 'text'
        | 'email'
        | 'password'
        | 'hidden'
        | 'datetime-local'
        | 'number'
        | 'date'
    defaultValue?: string | number
    placeholder?: string
    disabled?: boolean
    errorText?: string
    className?: string
    withCurrencySymbol?: boolean
    rules?: ValidationsProps
}

export const Input: React.FC<InputProps> = ({
    id,
    type,
    defaultValue,
    errorText,
    placeholder,
    className,
    rules,
    disabled,
    withCurrencySymbol,
}) => {
    const {
        register,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext()

    const value = watch(id)
    const isError = Boolean(errors[id])

    React.useEffect(() => {
        if (defaultValue && type === 'date') {
            setValue(id, defaultValue)
        }
    }, [defaultValue, type])

    return (
        <>
            <div className={classNames('relative', className)}>
                {withCurrencySymbol && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">€</span>
                    </div>
                )}
                <input
                    type={type}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    className={classNames(
                        isError
                            ? 'pr-10 border-red-300 text-red-900 focus:border-red-500 placeholder-red-300 focus:ring-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500',
                        disabled ? 'cursor-not-allowed text-gray-500' : '',
                        'block w-full focus:outline-none text-sm rounded-md py-2',
                        withCurrencySymbol ? 'pl-6' : ''
                    )}
                    placeholder={placeholder}
                    aria-invalid={isError ? 'true' : 'false'}
                    aria-describedby={isError ? `${id}-error` : `${id}-input`}
                    {...register(id, rules)}
                />
                {isError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
                {type === 'date' && value && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
                        <span
                            className="text-gray-500 sm:text-sm"
                            onClick={() => setValue(id, null)}
                        >
                            <XIcon className="h-4 w-4" />
                        </span>
                    </div>
                )}
            </div>
            {errorText && <ErrorText id={id}>{errorText}</ErrorText>}
        </>
    )
}
