import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import OtpInput from 'react-otp-input'
import type { ValidationsProps } from './validation-props'

export interface OTPInputProps {
    id: string
    placeholder?: string
    numInputs?: number
    disabled?: boolean
    rules?: Omit<ValidationsProps, 'valueAsDate'>
}

export const OTPInput: React.FC<OTPInputProps> = ({
    id,
    placeholder,
    numInputs,
    rules,
    disabled,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const isError = React.useMemo(() => Boolean(errors[id]), [errors[id]])

    return (
        <div className="relative">
            <Controller
                control={control}
                name={id}
                rules={rules}
                render={({ field }) => (
                    <OtpInput
                        value={field.value}
                        onChange={(otp: string) =>
                            field.onChange(otp.toUpperCase())
                        }
                        shouldAutoFocus
                        inputType="number"
                        // isDisabled={disabled}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="flex flex-row justify-between"
                        inputStyle="block w-full focus:outline-none sm:text-sm rounded-md py-2 h-12 !w-12 border-2 border-gray-400 text-black selection:text-indigo-900 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        numInputs={numInputs || 6}
                        placeholder={placeholder}
                        aria-invalid={isError ? 'true' : 'false'}
                        aria-describedby={
                            isError ? `${id}-error` : `${id}-input`
                        }
                    />
                )}
            />
            {isError && (
                <div className="absolute -right-1 -top-1 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                </div>
            )}
        </div>
    )
}
