import {
    CheckIcon,
    ChevronDownIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/solid'
import { Combobox, Transition } from '@headlessui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { classNames, Spinner } from 'lib/shared-ui'
import * as React from 'react'
import { Fragment } from 'react'
import { ValidationsProps } from './validation-props'

interface Option {
    id: string
    title: string
    subtitle?: string
}

export interface AutocompleteProps {
    id: string
    placeholder?: string
    defaultValue?: string | number
    isLoading?: boolean
    disabled?: boolean
    className?: string
    onQuery: (term: string) => void
    onCreate?: (term: string) => void
    displayValue: (item: Option) => string
    options: Option[]
    rules?: ValidationsProps
}

export function Autocomplete({
    id,
    placeholder,
    defaultValue,
    isLoading,
    onQuery,
    onCreate,
    displayValue,
    options,
    rules,
}: AutocompleteProps) {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const defaultOption = defaultValue
        ? options.find((o) => o.id === defaultValue)
        : []

    const [query, setQuery] = React.useState('')

    const isError = Boolean(errors[id])

    return (
        <>
            <Controller
                control={control}
                name={id}
                defaultValue={defaultOption}
                rules={rules}
                render={({ field }) => (
                    <Combobox
                        as="div"
                        value={field.value}
                        onChange={field.onChange}
                    >
                        {({ open }) => (
                            <>
                                <div className="mt-1 relative">
                                    <Combobox.Input
                                        placeholder={placeholder}
                                        name={field.name}
                                        autoComplete="false"
                                        type="search"
                                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm"
                                        onChange={(event) => {
                                            onQuery(event.target.value)
                                            setQuery(event.target.value)
                                        }}
                                        displayValue={displayValue}
                                    />
                                    {isError && (
                                        <div className="absolute inset-y-0 -right-5 -top-2 pr-3 flex items-start pointer-events-none">
                                            <ExclamationCircleIcon
                                                className="h-5 w-5 text-red-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    )}
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        {isLoading ? (
                                            <Spinner size={16} />
                                        ) : (
                                            <ChevronDownIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Combobox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Combobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {options.map((option) => (
                                                <Combobox.Option
                                                    key={option.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active
                                                                ? 'text-white bg-indigo-600'
                                                                : 'text-gray-900',
                                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={option}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span
                                                                className={classNames(
                                                                    selected
                                                                        ? 'font-semibold'
                                                                        : 'font-normal',
                                                                    'block truncate'
                                                                )}
                                                            >
                                                                {option.title}
                                                            </span>
                                                            <span
                                                                className={classNames(
                                                                    active ||
                                                                        selected
                                                                        ? 'text-white'
                                                                        : 'text-gray-500',
                                                                    'block truncate text-xs'
                                                                )}
                                                            >
                                                                {
                                                                    option.subtitle
                                                                }
                                                            </span>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active
                                                                            ? 'text-white'
                                                                            : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                            {onCreate &&
                                                !isLoading &&
                                                query.length > 0 &&
                                                options.length === 0 && (
                                                    <div
                                                        className="cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900"
                                                        onClick={() =>
                                                            onCreate(query)
                                                        }
                                                    >
                                                        Create &quot;{query}
                                                        &quot;
                                                    </div>
                                                )}
                                        </Combobox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Combobox>
                )}
            />
        </>
    )
}
